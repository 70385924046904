import React from "react"
import Img from "gatsby-image"

const ZigzagImage = ({ fluid, alt = "" }) => {
  if (!fluid) {
    return null
  }
  return (
    <Img
      fluid={fluid}
      alt={alt}
      className={"w-100"}
    />
  )
}

export default ZigzagImage
